<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <profile-header :header-data="profileData.header" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <!--          <b-card>-->
          <!--            <div>-->
          <!--              &lt;!&ndash; about &ndash;&gt;-->
          <!--              <h5 class="text-capitalize mb-75">-->
          <!--                titre-->
          <!--              </h5>-->
          <!--              <b-card-text>-->
          <!--                description-->
          <!--              </b-card-text>-->
          <!--            </div>-->
          <!--          </b-card>-->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <b-card>
            <form
              ref="form"
              @submit.stop.prevent="handleSubmit"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    :state="user.usernameState"
                    label="Login de connexion"
                    label-for="username-input"
                    invalid-feedback="Le login est requis"
                  >

                    <b-form-input
                      id="username-input"
                      v-model="user.username"
                      :state="user.usernameState"
                      required
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :state="user.passwordState"
                    label=" Mot de passe"
                    label-for="mot-de-passe-input"
                    invalid-feedback="Le mot de passe est requis"
                  >

                    <b-input-group>
                      <b-form-input
                        id="mot-de-passe-input"
                        v-model="user.password"
                        :state="user.passwordState"
                        :type="showPassword ? 'text' : 'password'"
                        required
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon()"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </form>
            <div style="text-align: center">
              <b-overlay
                :show="show"
                rounded="sm"
                spinner-variant="primary"
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="save()"
                >
                  Enregistrer
                </b-button>
              </b-overlay>
            </div>
          </b-card>
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col
          lg="3"
          cols="12"
          order="3"
        />
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <b-col
          cols="12"
          order="4"
        >
          <!--          <profile-bottom />-->
        </b-col>
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput, BFormGroup, BInputGroupAppend, BButton, BInputGroup, BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'
import store from '@/store'
import profileBottom from './profileBottom.vue'
import ProfileHeader from './ProfileHeader.vue'

/* eslint-disable global-require */
export default {
  components: {
    BOverlay,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardText,
    BCard,
    BRow,
    BCol,

    ProfileHeader,
    profileBottom,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      showPassword: false,
      user: {
        nom: '',
        prenom: '',
        telephone: '',
        email: '',
        canal: '',
        responsable: '',
        profil: '',
        username: '',
        password: '',
        nomState: null,
        prenomState: null,
        responsableState: null,
        profilState: null,
        telephoneState: null,
        canalState: null,
        emailState: null,
        usernameState: null,
        passwordState: null,
      },

      profileData: { },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.$http.get('/profile/data').then(res => { this.profileData = res.data })
    this.resetData()
  },
  methods: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    save() {
      this.show = true
      this.$http.post(`${API_URL}save-pass`, this.user).then(() => {
        this.show = false
        // this.$router.push('/profile')
        showToast(this, 'primary', 'Enregistrement', 'Mot de passe modifié avec succès')
      }).catch(() => {
        this.show = false
        showToast(this, 'danger', 'Enregistrement', 'Erreur lors de la modification du mot de passe')
        // this.$router.push('/profile')
      })
      // Simulate an async request
      // setTimeout(() => {
      //   this.show = false
      // }, 5000)
    },
    resetData() {
      this.user.uuid = this.userData.uuid
      this.user.username = this.userData.username
    },
    checkFormValidity() {
      this.user.profileState = !!this.user.profile
      this.user.nomState = this.user.nom.length > 0
      this.user.prenomState = this.user.prenom.length > 0
      this.user.telephoneState = this.user.telephone.length > 0
      this.user.usernameState = this.user.username.length > 0
      this.user.passwordState = this.user.password.length > 0
      return this.user.nomState
        && this.user.prenomState
        && this.user.telephoneState
        && this.user.profileState
        && this.user.usernameState
        && this.user.passwordState
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saveUsers()
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
